// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-index-en-js": () => import("./../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-fr-js": () => import("./../src/pages/about/index.fr.js" /* webpackChunkName: "component---src-pages-about-index-fr-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-origallery-index-en-js": () => import("./../src/pages/origallery/index.en.js" /* webpackChunkName: "component---src-pages-origallery-index-en-js" */),
  "component---src-pages-origallery-index-fr-js": () => import("./../src/pages/origallery/index.fr.js" /* webpackChunkName: "component---src-pages-origallery-index-fr-js" */)
}

